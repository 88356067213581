import { BASE_URL } from './config';
import {removeEmojis} from './utils';


export const fetchCharacterCode = async (user_name) => {
    try {
      const response = await fetch(`${BASE_URL}/api/get_character_code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name: user_name,
        }),
      });
      const data = await response.json();
      if (data.status === 'Success.') {
        localStorage.setItem("voiceCharacter", data.response.assistant_character);
        
        return data.response.assistant_character;
      } else {
        localStorage.setItem("voiceCharacter", 'BAIA-F-01_English-US');
        console.error('Error fetching character code:', data.process_status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching character code:', error);
      return null;
    }
  };

export const checkInitialSubscription = async (user_name) => {
  const payload = { user_name };

  try {
    const response = await fetch(`${BASE_URL}/api/initial_access_ma`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    const { enable_casual_chat_session_status, enabled_mood_analysis, enabled_assesments } = data.response;
    const isActive = enable_casual_chat_session_status && enabled_mood_analysis && enabled_assesments;
    localStorage.setItem('chat',enable_casual_chat_session_status)
    localStorage.setItem('assessment',enabled_assesments)
    localStorage.setItem('analysis',enabled_mood_analysis)
    localStorage.setItem('initial_subscription', JSON.stringify(data.response));
    
    // Check if 'Logged_by' is not set to 'login' in localStorage
    if (localStorage.getItem('Logged_by') !== 'login') {
      // In this case, set 'chatType' to 'freshChat' and then navigate to '/home'
      localStorage.setItem('chatType', 'fresh');
    } else {
      // If 'Logged_by' is 'login', navigate to '/home'
      localStorage.setItem('chatType', 'previous');
    }

    

    
    return data.response;
  } catch (error) {
    console.error('Error checking initial subscription:', error);
    return false;
  }
};
const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0'); // Days of the month

  return `${year}-${month}-${day}`;
};

export const checkCentralSubscription = async (userName,email_id) => {
  const payload = {
    email: email_id,
    initial_trial_period: 'True',
    user_name:userName,
    date:getCurrentDate(),
  };

  try {
    const response = await fetch(`${BASE_URL}/api/central_access_ma`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    // const { enable_casual_chat_session_status, enabled_mood_analysis, enabled_assesments } = data.response;
    // const isActive = enable_casual_chat_session_status && enabled_mood_analysis && enabled_assesments;
const isActive=data.response.subscription_status
    localStorage.setItem('available_subscription', JSON.stringify(isActive));
    localStorage.setItem('product_name', JSON.stringify(data.response.product_name));
    // localStorage.setItem('subscription_status', JSON.stringify(data.response.subscription_status));
    sessionStorage.setItem("central_detail",JSON.stringify(data.response))
    localStorage.setItem('daily_limit',JSON.stringify(data.response.daily_time_limit_quota))
    return  data.response;
  } catch (error) {
    console.error('Error checking central subscription:', error);
    return false;
  }
};

export const fetchCurrentLanguage = async (user_name) => {
  const payload = { user_name };

  try {
    const response = await fetch(`${BASE_URL}/api/get_language`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    const { language_selected } = data.response;

    return language_selected;
  } catch (error) {
    console.error('Error fetching current language:', error);
    return null;
  }
};

export const fetchAndRenderImage = async (userName,setProfilePic) => {
    try {
      const response = await fetch(`${BASE_URL}/fetch_image?user_name=${userName}`);
      const data = await response.json();
  
      if (data.success) {
        setProfilePic(data.response);
        localStorage.setItem('image_data',data.response)
        // console.log(data.response)
        // console.log("Image fetched successfully");
      } else {
        // setProfilePic(initialImage); // Assuming initialImage is defined
        console.error("No image found for this user");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  export const fetchPreviousChat = async (user_name, days, continuous) => {
    try {
      const switch_lan=JSON.parse(sessionStorage.getItem('Chat_flag'));
      // console.log('switch-lang_prev',switch_lan)
      const response = await fetch(`${BASE_URL}/${continuous}_sl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name,
          days: days.toString(),
          role: 'user',
          switch_lang:switch_lan
        }),
      });
  
      const data = await response.json();
      if (data.status === 'Success.') {
        console.log("**previous**",data.response)
        const messagesWithAudio = data.response.map(msg => {
          if (msg.role === 'assistant' && msg.display_message) {
            const voice = localStorage.getItem('voiceCharacter');
            const cleanText = removeEmojis(msg.content);
            const ttsPayload = {
              text: cleanText,
              character_voice_code: voice,
            };
            return fetch(`${BASE_URL}/api/text-to-speech`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(ttsPayload),
            })
              .then(response => response.blob())
              .then(blob => {
                const audioUrl = URL.createObjectURL(blob);
                return { ...msg, audioUrl };
              })
              .catch(error => {
                console.error('Error converting text to speech:', error);
                return msg;
              });
          } else {
            return Promise.resolve(msg);
          }
        });
  
        const finalMessages = await Promise.all(messagesWithAudio);
        localStorage.setItem('chatType', 'previous');
        sessionStorage.setItem('previousChat', JSON.stringify(finalMessages));
        // console.log(finalMessages);
        return finalMessages;
      }
    } catch (error) {
      console.error('Error fetching previous chat messages:', error);
      return [];
    }
  };
  
  export const fetchFreshChat = async (user_name, fresh) => {
    try {
      const switch_lan=JSON.parse(sessionStorage.getItem('Chat_flag'));
      // console.log('switch-lang_fresh',switch_lan)
      const response = await fetch(`${BASE_URL}/${fresh}_sl`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_name,
          days: '1',
          role: 'user',
          switch_lang:switch_lan
        }),
      });
  
      const data = await response.json();
      if (data.status === 'Success.') {
        console.log("**FreshChat**",data.response)
        const messagesWithAudio = data.response.map(msg => {
          if (msg.role === 'assistant' && msg.display_message) {
            const voice = localStorage.getItem('voiceCharacter');
            const cleanText = removeEmojis(msg.content);
            const ttsPayload = {
              text: cleanText,
              character_voice_code: voice,
            };
            return fetch(`${BASE_URL}/api/text-to-speech`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(ttsPayload),
            })
              .then(response => response.blob())
              .then(blob => {
                const audioUrl = URL.createObjectURL(blob);
                return { ...msg, audioUrl };
              })
              .catch(error => {
                console.error('Error converting text to speech:', error);
                return msg;
              });
          } else {
            return Promise.resolve(msg);
          }
        });
  
        const finalMessages = await Promise.all(messagesWithAudio);
        localStorage.setItem('chatType', 'fresh');
        sessionStorage.setItem('freshChat', JSON.stringify(finalMessages));
        // console.log("fresh",finalMessages);
        return finalMessages;
      }
    } catch (error) {
      console.error('Error fetching fresh chat messages:', error);
      return [];
    }
  };
  


  export const fetchAvailedTime = async (username) => {
    const payload = { user_name: username };
  
    try {
      const response = await fetch(`${BASE_URL}/api/fetch_availed_time`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch total time');
      }
  
      const data = await response.json();
  
      const { status, response: totalSeconds, process_status } = data;
      if (status === 'Success.') {
        // console.log("Total time fetched",totalSeconds)
        sessionStorage.setItem("availed-time",totalSeconds)
        return totalSeconds;
      } else {
        console.error('Failed to fetch total time:', process_status);
        throw new Error('Process status indicates failure');
      }
    } catch (error) {
      console.error('Error fetching total time:', error);
      throw error;
    }
  };


// Function to update voice consent
export const updateVoiceConcent = async (userName, concent) => {
  try {
    const response = await fetch(`${BASE_URL}/api/update_voice_concent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: userName,
        concent: concent,
        key_date: new Date().toISOString(), // Current timestamp
      }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating voice consent:', error);
    throw error;
  }
};

// Function to fetch voice consent
export const fetchVoiceConcent = async (userName) => {
  try {
    const response = await fetch(`${BASE_URL}/api/fetch_voice_concent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: userName,
      }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    // console.log('Fetched voice consent:', data);
    return data;
  } catch (error) {
    console.error('Error fetching voice consent:', error);
    throw error;
  }
};




export const fetchFontSize = async (userName) => {
  try {
      const response = await fetch(`${BASE_URL}/api/get_font_size`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_name: userName }),
      });
      
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      
      const data = await response.json();

      // Check if data and data.response exist
      if (data && data.response && data.response.font_size) {
          const fontSize = data.response.font_size;
          sessionStorage.setItem('font_size', fontSize);
          return fontSize;
      } else {
          // Handle the case where font_size is not available
          console.warn('Font size data is missing or malformed:', data);
          return '16'; // Default font size if none is provided
      }
  } catch (error) {
      console.error('Error fetching font size:', error);
      return '16'; // Default font size in case of error
  }
};



export const updatebrowserlocation = async (userName, latitude, longitude) => {
  try {
    const response = await fetch(`${BASE_URL}/api/update_location_code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: userName,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        timestamp: new Date().toISOString()
       
      }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating browser location:', error);
    throw error;
  }
};
