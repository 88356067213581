


import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { saveSessionData, getSessionData, clearSessionData } from './utils/session';
import { Box, CircularProgress, Typography } from '@mui/material';
import img from "./Gif/baia-walking-animation.gif"
const AuthContext = createContext();

const COGNITO_DOMAIN = "https://baia-extfed.auth.eu-central-1.amazoncognito.com";
const CLIENT_ID = "2lbp3ll68lo92r6104dae81dki";
const CLIENT_SECRET = "6j26607j2jtnj149mtq86ls12l3uj4m4poeick1g0815p6ofcea";
//const APP_URI = 'https://talk.baia.ai/'
//const APP_URI = 'https://chat.baia.ai/'
// const APP_URI='http://localhost:3000/'
const APP_URI='https://bait24.l4b.ai/'

export const AuthProvider = ({ children }) => {
  const [authCode, setAuthCode] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setAuthCode(code);
      fetchTokens(code);
    } else {
      // Check session storage for existing session
      const sessionUserInfo = getSessionData();
      if (sessionUserInfo) {
        localStorage.setItem('flag','true')
        setUserInfo(sessionUserInfo);
        setLoggedIn(true);
        
        
      }
    }
  }, []);

  const fetchTokens = async (authCode) => {
    const tokenUrl = `${COGNITO_DOMAIN}/oauth2/token`;
    const clientSecretString = `${CLIENT_ID}:${CLIENT_SECRET}`;
    const clientSecretEncoded = btoa(clientSecretString);

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Basic ${clientSecretEncoded}`
    };

    const body = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: CLIENT_ID,
      code: authCode,
      redirect_uri: APP_URI
    });

    try {
      const response = await axios.post(tokenUrl, body, { headers });
      setAccessToken(response.data.access_token);
      setIdToken(response.data.id_token);
      fetchUserInfo(response.data.access_token);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const fetchUserInfo = async (token) => {
    const userInfoUrl = `${COGNITO_DOMAIN}/oauth2/userInfo`;

    try {
      const response = await axios.get(userInfoUrl, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${token}`
        }
      });
      setUserInfo(response.data);
      setLoggedIn(true);
      // console.log("loggedin",loggedIn)
      // Store user info in session storage
      saveSessionData(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const loginUrl = `${COGNITO_DOMAIN}/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${APP_URI}`;
  const logoutUrl = `${COGNITO_DOMAIN}/logout?client_id=${CLIENT_ID}&redirect_uri=${APP_URI}&response_type=code`;

  const handleLogin = () => {
    window.location.href = loginUrl;
  };

  const handleLogout = () => {
    setLoading(true); // Start the loading process
    clearSessionData();
    setLoggedIn(false);
    setUserInfo(null);

// Remove specified items from localStorage
localStorage.removeItem('available_subscription');
localStorage.removeItem('product_name');
localStorage.removeItem('subscription_status');
localStorage.removeItem('initial_subscription')
localStorage.removeItem('voiceCharacter')   
localStorage.removeItem('chatType')
localStorage.removeItem('timerData')
localStorage.removeItem('gender')
localStorage.removeItem('days')
localStorage.removeItem('trail_status')
localStorage.removeItem('flag')
localStorage.removeItem('image_data')
localStorage.removeItem('Logged_by')
localStorage.removeItem('daily_limit')
navigate('/');
setLoading(false)
// Simulate a delay for the loading screen
    // setTimeout(() => {
    //   navigate('/');
    //   setLoading(false); // Stop the loading process after navigation
    // }, 3000); // 2 seconds delay
  };

  return (
    <AuthContext.Provider value={{ loggedIn, userInfo, handleLogin, handleLogout }}>
      {loading ? (
        <div className='loading-container'>
        <div className="loading-content">
         <img 
           src={img} 
           alt="Loading" 
           className="loading-image" 
         />
         <Typography variant="h7" gutterBottom className="loading-text">
           Logging Out
         </Typography>
       </div>
       </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;
